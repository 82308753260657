import React, { useContext, useEffect, useState } from "react";
import { detectDeviceType } from "../../utils/common";
import { useWindowSize } from "../../utils/useWindowSize";

const MetaContext = React.createContext({
    deviceType: undefined,
});

export const useMetaContext = () => useContext(MetaContext);

export const MetaContextProvider = ({ children }) => {
    const [deviceType, setDeviceType] = useState(detectDeviceType());

    const [screenWidth] = useWindowSize();

    useEffect(() => {
        const detectedDeviceType = detectDeviceType();

        if (deviceType !== detectedDeviceType) {
            setDeviceType(detectDeviceType());
        }
    }, [deviceType, screenWidth]);

    return (
        <MetaContext.Provider
            value={{
                deviceType,
            }}
        >
            {children}
        </MetaContext.Provider>
    );
};
