import { notification } from "antd";

export const useNotification = () => {
  const [api, contextHolder] = notification.useNotification();

  //success | error | info | warning
  const notify = ({ type = "success", message, title = "" }) => {
    api[type]({
      message: title || type.charAt(0).toUpperCase() + type.slice(1),
      description: message,
    });
  };

  return { notify, contextHolder };
};
