import { Col, Row } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useMetaContext } from "../core/contexts/MetaContextProvider";
import { DeviceType } from "../utils/constants";
import { _STYLES } from "../utils/customStyles";
import CreatePostForm from "./forms/CreatePostForm";

const { postFormWrap, subHeader } = _STYLES;

const useStyles = createUseStyles({
  postFormWrap,
  subHeader,
});

const CreatePost = () => {
  const classes = useStyles();

  const { deviceType } = useMetaContext();

  const isMobile = deviceType === DeviceType.mobile;

  return (
    <Col className={classes.postFormWrap}>
      <Row gutter={[0, 24]}>
        <Col xs={24}>
          <h1 className={classes.subHeader}>Create New Post</h1>
        </Col>
        <Col xs={24}>
          <CreatePostForm />
        </Col>
      </Row>
    </Col>
  );
};

export default CreatePost;
