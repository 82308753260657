import { useNavigate } from "react-router-dom";

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate(-1);

    return (
        <div>
            YOU IS NOT AUTHORIZED TO SEE THIS PAGE
            <button onClick={goBack}>GO BACK</button>
        </div>
    )
}

export default Unauthorized;