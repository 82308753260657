import { Col } from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { _STYLES } from "../../utils/customStyles";

const {
    container,
    padTopXl,
    padTopLg,
    padTopMd,
    padTopSm,
    padBottomLg,
    padBottomMd,
    padBottomSm,
    padBottomXl,
} = _STYLES;
const useStyles = createUseStyles({
    container,
    padTopXl,
    padTopLg,
    padTopMd,
    padTopSm,
    padBottomLg,
    padBottomMd,
    padBottomSm,
    padBottomXl,
});

export const CustomContainer = (props) => {
    const classes = useStyles();
    const generatePadding = (padding, direction) => {
        let padArr = [
            {
                type: "xl",
                class: classes.padTopXl,
                dir: "top",
            },
            {
                type: "lg",
                class: classes.padTopLg,
                dir: "top",
            },
            {
                type: "md",
                class: classes.padTopMd,
                dir: "top",
            },
            {
                type: "sm",
                class: classes.padTopSm,
                dir: "top",
            },
            {
                type: "xl",
                class: classes.padBottomXl,
                dir: "bottom",
            },
            {
                type: "lg",
                class: classes.padBottomLg,
                dir: "bottom",
            },
            {
                type: "md",
                class: classes.padBottomMd,
                dir: "bottom",
            },
            {
                type: "sm",
                class: classes.padBottomSm,
                dir: "bottom",
            },
        ];
        let padClass =
            padArr.find((x) => x.dir === direction && x.type === padding)?.class ||
            "";
        return padClass;
    };

    const padTopClass = generatePadding(props.padTop, "top");
    const padBottomClass = generatePadding(props.padBottom, "bottom");
    const styles =
        classes.container +
        " " +
        padTopClass +
        " " +
        padBottomClass +
        " " +
        (props.className || "");

    const elProps = {
        className: styles,
    };

    if (props.id) {
        elProps.id = props.id;
    }

    return <Col {...elProps}>{props.children}</Col>;
};
