import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Form, Input, Row } from "antd";
import ls from "localstorage-slim";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useEndpoints from "../../hooks/useEndpoints";
import { CustomContainer } from "../common/components/container";
import { _STYLES } from "../utils/customStyles";

const { mainHeader, loginContainer, loginWrap, loginIcons } = _STYLES;
const useStyles = createUseStyles({
  mainHeader,
  loginContainer,
  loginWrap,
  loginIcons,
});

const Login = () => {
  const classes = useStyles();
  const { setAuth } = useAuth();
  const { login } = useEndpoints();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathName || "/";

  const onFinishFailed = useCallback(() => { }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const onFinish = async (values) => {
    setIsLoading(true);

    try {
      const { jwt, refreshToken, expiresIn } = await login(
        values.email,
        values.password
      );

      if (rememberMe) {
        ls.set("jwt", jwt, { ttl: parseInt(expiresIn), encrypt: true });
      }

      ls.set("refreshToken", refreshToken, {
        ttl: 60 * 60 * 24,
        encrypt: true,
      });

      setAuth({ jwt, refreshToken });

      navigate(from, { replace: true });
    } catch (err) {
      alert("an error occurred");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Col className={classes.loginWrap}>
      <CustomContainer padTop="md" padBottom="md">
        <Form
          name="login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row gutter={[0, 32]} className={classes.loginContainer}>
            <Col xs={24}>
              <h1 className={`${classes.mainHeader}`}>Login</h1>
              <p>Enter your details to sign in to your account</p>
            </Col>
            <Col xs={24}>
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email",
                  },
                ]}
              >
                <Input
                  autoFocus
                  placeholder="Enter your email"
                  addonBefore={<UserOutlined className={classes.loginIcons} />}
                  size="large"
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label=""
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input
                  addonBefore={<LockOutlined className={classes.loginIcons} />}
                  placeholder="Enter your password"
                  size="large"
                  allowClear
                  type="password"
                />
              </Form.Item>
              <Form.Item>
                <Checkbox
                  checked={rememberMe}
                  onChange={(evt) => setRememberMe(evt.target.checked)}
                >
                  Remember me
                </Checkbox>
              </Form.Item>
              <Button
                loading={isLoading}
                className={classes.customFont}
                shape="round"
                size="large"
                type="primary"
                htmlType="submit"
                block
              >
                Login
              </Button>
            </Col>
          </Row>
        </Form>
      </CustomContainer>
    </Col>
  );
};

export default Login;
