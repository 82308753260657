import {
  LoadingOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Radio, Select, Spin, Upload } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useCallback, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDebouncedCallback } from "use-debounce";
import useEndpoints from "../../../hooks/useEndpoints";
import { useNotificationContext } from "../../core/contexts/NotificationContextProvider";
import { _STYLES } from "../../utils/customStyles";

const { postForm, gridStretch } = _STYLES;

const useStyles = createUseStyles({
  postForm,
  gridStretch,
});

const animalTypeOptions = [
  {
    value: "cat",
    label: "Cat",
  },
  {
    value: "dog",
    label: "Dog",
  },
];

const SPONSOR_OPTIONS = [
  {
    label: "Transport",
    value: "transport",
  },
  {
    label: "Food",
    value: "food",
  },
  {
    label: "Medicine",
    value: "medicine",
  },
];

const POST_TYPES = {
  adopt: "adopt",
  sponsor: "sponsor",
};

const CreatePostForm = () => {
  const classes = useStyles();

  const [fileList, setFileList] = useState([]);
  const [saving, setSaving] = useState(false);

  const [isLocationSearch, setIsLocationSearch] = useState(false);

  const [postType, setPostType] = useState("adopt");
  const [gender, setGender] = useState("male");
  const [sterilization, setSterilization] = useState("yes");
  const [transportation, setTransportation] = useState("yes");
  const [description, setDescription] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [animalType, setAnimalType] = useState("cat");
  const [sponsorOptions, setSponsorOptions] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [locationItems, setLocationItems] = useState([]);

  const { notify } = useNotificationContext();

  const { createPost, uploadPhotos, getLocationDataForInput } = useEndpoints();

  const handleSearchLocation = useCallback(
    (location) => {
      if (location) {
        getLocationDataForInput(location)
          .then((data) => setLocationItems(data))
          .finally(() => setIsLocationSearch(false));
      }
    },
    [getLocationDataForInput]
  );

  const debouncedSearch = useDebouncedCallback(handleSearchLocation, 750);

  const handleSearch = useCallback(
    (searchVal) => {
      setIsLocationSearch(true);
      debouncedSearch(searchVal);
    },
    [debouncedSearch]
  );

  const handlePostCreate = async () => {
    try {
      let post = {
        type: postType,
        description,
        contactNo,
        animalType,
      };

      if (postType === "adopt") {
        post = {
          ...post,
          location: selectedLocation,
          gender,
          sterilization,
          transportation,
        };
      }

      // to be implemented
      /* if (postType === 'sponsor') {
        post = {
          ...post,
          sponsorFor: sponsorOptions
        };
      } */

      setSaving(true);

      const postId = await createPost(post);
      await uploadPhotos(postId, fileList);

      notify({
        message: "Post Created",
      });
    } catch (err) {
      notify({
        type: "error",
        message: "Post could not be created",
      });
    } finally {
      setSaving(false);
    }
  };

  const onPostTypeSelectionChanged = ({ target: { value } }) =>
    setPostType(value);
  const onGenderSelectionChanged = ({ target: { value } }) => setGender(value);
  const onSterilizationSelectionChanged = ({ target: { value } }) =>
    setSterilization(value);
  const onTransportationSelectionChanged = ({ target: { value } }) =>
    setTransportation(value);
  const onDescriptionChanged = ({ target: { value } }) => setDescription(value);
  const onContactNoChanged = ({ target: { value } }) => setContactNo(value);
  const onAnimalTypeSelectionChanged = (value) => setAnimalType(value);
  const onSponsorOptionsChange = (value) => setSponsorOptions(value);

  return (
    <Form className={classes.postForm} layout="vertical">
      <Form.Item label="Post Type">
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          value={postType}
          onChange={onPostTypeSelectionChanged}
        >
          <Radio value={POST_TYPES.adopt}>Adopt</Radio>
          <Radio value={POST_TYPES.sponsor}>Sponsor</Radio>
          {/* <Radio value="lostPet">Lost Pet</Radio> */}
        </Radio.Group>
      </Form.Item>
      {postType === POST_TYPES.adopt && (
        <>
          <Form.Item label="Gender">
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              value={gender}
              onChange={onGenderSelectionChanged}
            >
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
              <Radio value="idk">Not Sure</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Sterilization">
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              value={sterilization}
              onChange={onSterilizationSelectionChanged}
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
              <Radio value="idk">Not Sure</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Transportation">
            <Radio.Group
              optionType="button"
              buttonStyle="solid"
              value={transportation}
              onChange={onTransportationSelectionChanged}
            >
              <Radio value="yes">Yes</Radio>
              <Radio value="no">No</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Location">
            <Select
              defaultActiveFirstOption={false}
              filterOption={false}
              showSearch
              placeholder={"Search for a Location"}
              suffixIcon={
                isLocationSearch ? (
                  <Spin indicator={<LoadingOutlined spin />} size="default" />
                ) : (
                  <SearchOutlined />
                )
              }
              onSearch={handleSearch}
              notFoundContent={null}
              onChange={(e, o) => {
                const { value: id, label: name } = o;
                setSelectedLocation({ id, name });
              }}
              options={locationItems.map((d) => ({
                value: d.id,
                label: d.name,
              }))}
            />
          </Form.Item>
        </>
      )}

      {postType === POST_TYPES.sponsor && (
        <Form.Item label="I want to be sponsored for">
          <Select
            mode="multiple"
            value={sponsorOptions}
            onChange={onSponsorOptionsChange}
            options={SPONSOR_OPTIONS}
          />
        </Form.Item>
      )}
      <Form.Item label="Description">
        <TextArea value={description} onChange={onDescriptionChanged} />
      </Form.Item>
      <Form.Item label="Contact Number">
        <Input value={contactNo} onChange={onContactNoChanged} />
      </Form.Item>

      <Form.Item label="Animal Type">
        <Select
          options={animalTypeOptions}
          value={animalType}
          onChange={onAnimalTypeSelectionChanged}
        />
      </Form.Item>

      <Form.Item className={classes.gridStretch} label="Photos">
        <Upload
          listType="picture-card"
          multiple={true}
          beforeUpload={(file, files) => {
            const fileListCopy = fileList.slice();
            const newFiles = fileListCopy.concat([...files]);

            setFileList(newFiles);

            return false;
          }}
          onRemove={(file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          }}
        >
          <button
            style={{
              border: 0,
              background: "none",
            }}
            type="button"
          >
            <PlusOutlined />
            <div
              style={{
                marginTop: 8,
              }}
            >
              Upload
            </div>
          </button>
        </Upload>
      </Form.Item>
      <Form.Item>
        <Button
          icon={<PlusOutlined />}
          onClick={handlePostCreate}
          type="primary"
          loading={saving}
        >
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreatePostForm;
