import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async (oldRefreshToken) => {
    const response = await axios.post("auth/refresh", {
      refreshToken: oldRefreshToken,
    });

    setAuth((prev) => {
      return {
        ...prev,
        jwt: response.data.jwt,
        refreshToken: response.data.refreshToken,
      };
    });

    return { jwt: response.data.jwt, refreshToken: response.data.refreshToken };
  };

  return refresh;
};

export default useRefreshToken;
