import { UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Carousel,
  Col,
  Empty,
  Modal,
  Row,
  Space,
  Statistic,
} from "antd";
import React from "react";
import { createUseStyles } from "react-jss";
import { useMetaContext } from "../core/contexts/MetaContextProvider";
import { DeviceType } from "../utils/constants";
import { _STYLES } from "../utils/customStyles";
import Image from "../utils/image";
import { _THEME } from "../utils/theme";

const {
  sectionHeader,
  textMd1,
  miniHeader,
  subHeader,
  fontLight,
  paraLine,
  headerLine,
  fontMid,
  blogDetailImage,
  mb2,
  stripLeft,
  h100,
  colorPrimary,
  blogInner,
  textSm3,
  colorText,
  fontRegular,
  w100,
  colorSubText,
  flexColumn,
  statWrap,
  customFont,
  mt2,
} = _STYLES;
const useStyles = createUseStyles({
  sectionHeader,
  textMd1,
  miniHeader,
  subHeader,
  fontLight,
  paraLine,
  headerLine,
  fontMid,
  blogDetailImage,
  mb2,
  stripLeft,
  h100,
  colorPrimary,
  blogInner,
  textSm3,
  colorText,
  fontRegular,
  w100,
  colorSubText,
  flexColumn,
  statWrap,
  customFont,
  mt2,
});

const PostDetailsModal = ({
  post,
  isModalOpen,
  toggleModal,
  handleApproval,
  handleRejection,
}) => {
  const classes = useStyles();
  const { deviceType } = useMetaContext();
  const isResponsive = deviceType !== DeviceType.pc;
  const isMobile = deviceType === DeviceType.mobile;

  return (
    <Modal
      title={""}
      open={isModalOpen}
      onCancel={() => toggleModal(false)}
      width={isMobile ? "100%" : isResponsive ? 600 : 1280}
      footer={[
        <Button
          key="approve"
          type="primary"
          onClick={() => handleApproval(post)}
        >
          Approve
        </Button>,
        <Button
          key="reject"
          type="default"
          onClick={() => handleRejection(post)}
        >
          Reject
        </Button>,
      ]}
      maskClosable={false}
    >
      {post === null ? (
        <Empty
          imageStyle={{ height: 350 }}
          image="/images/post-not-found.png"
          description={
            <Space size={[0, 24]} className={classes.mt2} direction="vertical">
              <h1 className={classes.subHeader + " " + classes.customFont}>
                Post not found
              </h1>
            </Space>
          }
        ></Empty>
      ) : (
        <Space
          className={classes.w100}
          direction="vertical"
          size={[0, isResponsive ? 24 : 48]}
        >
          <Row
            gutter={[
              { sm: 0, xs: 0, md: 24 },
              { xs: 16, sm: 16 },
            ]}
          >
            <Col sm={24} xs={24} md={8}>
              {post.thumbnailUrls.length === 0 ? (
                <Image className={classes.blogDetailImage} />
              ) : (
                <Carousel autoplaySpeed={4000} draggable autoplay>
                  {post.thumbnailUrls.map((item, key) => (
                    <Image
                      key={key}
                      preview
                      className={classes.blogDetailImage}
                      src={item}
                      alt={`post-detail-${key}`}
                    />
                  ))}
                </Carousel>
              )}
            </Col>
            <Col sm={24} xs={24} md={16}>
              <Row gutter={[0, 24]}>
                <Col span={24}>
                  <h1
                    className={` ${classes.sectionHeader} ${classes.fontMid} ${classes.mb2} ${classes.stripLeft} ${classes.flexColumn}`}
                  >
                    {post.title}
                    <Space align="center" direction="horizontal">
                      <Avatar
                        style={{
                          backgroundColor: _THEME.color.primary.main,
                        }}
                        icon={<UserOutlined />}
                      />
                      <Space direction="vertical" size={[0, 4]}>
                        <h6 className={classes.fontMid + " " + classes.textMd1}>
                          {post.author.name}
                        </h6>
                        <p
                          className={
                            classes.colorSubText + " " + classes.textSm3
                          }
                        >
                          {post.howLongAgo}
                        </p>
                      </Space>
                    </Space>
                  </h1>
                </Col>
                <Col span={24}>
                  <p
                    className={` ${classes.textMd1} ${classes.paraLine} ${classes.headerLine}`}
                  >
                    {post.description}
                  </p>
                </Col>
                <Col span={24}>
                  <Row gutter={[16, 24]}>
                    <Col span={12}>
                      <Statistic
                        className={classes.statWrap}
                        title="Gender"
                        value={post.gender}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        className={classes.statWrap}
                        title="Sterilized"
                        value={post.sterilization}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        className={classes.statWrap}
                        title="Place Found"
                        value={post.location.name}
                      />
                    </Col>
                    <Col span={12}>
                      <Statistic
                        className={classes.statWrap}
                        title="Transport"
                        value={post.transportation}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Space>
      )}
    </Modal>
  );
};

export default PostDetailsModal;
