import { Col, ConfigProvider } from "antd";
import { createUseStyles } from "react-jss";
import { MetaContextProvider } from "../core/contexts/MetaContextProvider";
import { NotificationContextProvider } from "../core/contexts/NotificationContextProvider";
import globalStyle from "../globalStyles";
import { _STYLES } from "../utils/customStyles";
import { _THEME } from "../utils/theme";

const { bodyWrap, bodyInnerWrap } = _STYLES;
const useStyles = createUseStyles({
  bodyWrap,
  bodyInnerWrap,
});

const Layout = (props) => {
  const classes = useStyles();
  globalStyle();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: _THEME.color.primary.main,
        },
        components: {
          Typography: {
            colorPrimary: _THEME.color.text.main,
          },
          Layout: {
            triggerBg: _THEME.color.accent.main,
          },
        },
      }}
    >
      <MetaContextProvider>
        <NotificationContextProvider>
          <Col id="bodyWrap" className={classes.bodyWrap}>
            <Col className={classes.bodyInnerWrap}>{props.children}</Col>
          </Col>
        </NotificationContextProvider>
      </MetaContextProvider>
    </ConfigProvider>
  );
};

export default Layout;
